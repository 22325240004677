// Here you can add other styles
.date {
  width: 100%;
  margin-left: 10px;
  height: 32px;
}

.sidebar-brand {
  background-color: #193549;
}

.sidebar-toggler {
  background-color: #193549;
}

.sidebar-nav {
  background-color: #15232d;
}

.btn-primary {
  color: #ffffff;
}
.btn-primary:hover {
  color: #ffffff;
}

.btn-success {
  color: #ffffff;
}
.btn-success:hover {
  color: #ffffff;
}

.btn-primary.disabled {
  color: #fff;
}

.container-lg {
  max-width: 1664px;
}

.loadingIcon {
  width: 16px;
  margin-right: 6px;
  margin-left: -22px;
  opacity: 0;
  transition-property: margin, opacity;
  transition-duration: 0.2s;
}

.isLoading {
  margin-left: 0;
  opacity: 100%;
}

.btn:focus {
  color: #fff !important;
  box-shadow: unset !important;
}

.btn:hover {
  color: #fff !important;
}

.custom-tooltip-parent:hover .custom-tooltip {
  opacity: 1;
  transform: translate(-50%, -100%) scale(1);
}

.custom-tooltip-parent {
  position: relative;
}

.custom-tooltip {
  pointer-events: none;
  position: absolute;
  min-width: 100%;
  max-width: 240px;
  width: max-content;
  background: rgba(0, 0, 0, 0.75);
  color: #fff;
  padding: 6px 8px;
  word-wrap: break-word;
  box-sizing: border-box;
  left: 50%;
  transform-origin: center calc(100% + 15px);
  transform: translate(-50%, -100%) scale(0.5);
  top: -15px;
  border-radius: 3px;
  transition: all 0.1s ease;
  transition-delay: 0.2s;
  opacity: 0;
  font-size: 14px;
  line-height: 1.5715;
}

.custom-tooltip-tip {
  position: absolute;
  width: 100%;
  height: 15px;
  left: 50%;
  top: 100%;
  transform: translate(-50%, 0%);
  overflow-y: hidden;
}

.custom-tooltip-tip::after {
  content: '';
  position: absolute;
  width: 15px;
  height: 100%;
  background: rgba(0, 0, 0, 0.75);
  transform: translate(-50%, -50%) rotate(45deg);
  left: 50%;
}

.anticon svg {
  vertical-align: initial;
}

.ant-table-wrapper.ant-table-no-row-selection {
  .ant-table-selection-column {
    display: none;
  }
}
